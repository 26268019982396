<template>
  <v-container>
    <v-row justify="center" class="text-md-h4 text-sm-h5 font-weight-bold align-end margin80">
      <v-img :src="require('../assets/hatena.png')" max-height="70" max-width="70" class="margin-right20"></v-img>
      電力リバースオークションとは
    </v-row>
    <v-row class="text-md-h6 text-lg-h5 text-body-2">
      <v-col>
        一般的なオークションは、買い手側が購入する物品等に、他者より高い価格を提示することで落札する競り上げ方式の購入手法となります。
        <!-- eslint-disable-next-line -->
        一方で、<b>リバースオークション</b>は、売り手側が販売した物品等を、他社より低い価格を提示することで落札する<b>競り下げ方式</b>の販売手法となります。
      </v-col>
    </v-row>
    <v-row>
      <v-col offset="2" cols="4">
        <v-img :src="require('../assets/seriage.png')" :max-height="$vuetify.breakpoint.smAndUp ? 300 : 150" contain />
      </v-col>
      <v-col cols="4">
        <v-img :src="require('../assets/serisage.png')" :max-height="$vuetify.breakpoint.smAndUp ? 300 : 150" contain />
      </v-col>
    </v-row>
    <v-row class="text-md-h6 text-lg-h5 text-body-2">
      <v-col>
        低炭素電力は、通常の電力より再生可能エネルギー由来の電力の比率が向上する分、通常の電力よりコスト
        アップする傾向にありますが、他社の入札価格を見ながら再入札可能な
        <b>電力リバースオークション「エネオク」を活用することで、低炭素電力の調達をより適正価格で実現することが可能</b>
        となります。
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-img :src="require('../assets/eneoku-green.png')" max-height="300" contain></v-img>
      </v-col>
    </v-row>
    <v-row justify="center" class="text-md-h6 text-lg-h5 text-body-2 margin20">
      「エネオク」紹介動画は
      <a href="https://www.youtube.com/watch?v=M-Zex-s_RyU&feature=youtu.be" target="_blank">こちら</a>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.margin-right20 {
  margin-right: 20px;
}

.margin20 {
  margin: 20px 0;
}

.margin80 {
  margin-top: 80px;
}
</style>
