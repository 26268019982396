<template>
  <v-container fluid class="blue darken-3 margin150">
    <v-row id="saitama" justify="center" class="text-md-h4 text-sm-h5 white--text font-weight-bold title-margin">
      ＼さいたま市の取り組み／
    </v-row>
    <v-container class="white">
      <v-row>
        <v-col sm="7" cols="12">
          <v-row justify="center" class="text-md-h5 text-body-2 blue--text font-weight-bold">
            ゼロカーボンシティの表明
          </v-row>
          <v-row class="text-lg-h5 text-md-h6 text-body-2 font-weight-bold">
            <v-col>
              市は、令和２年7月に、ゼロカーボンシティ*を表明し、さいたま市内で地球温暖化の原因である二酸化炭素の排出削減につながる再生可能エネル
              ギーの拡大の取組を推進しています。
            </v-col>
          </v-row>
          <v-col class="text-md-body-1 text-caption">
            <v-row class="font-weight-bold">
              ＊ゼロカーボンシティ
            </v-row>
            <v-row>
              2050年までに二酸化炭素排出実質ゼロを目指す旨を首長自らが 又は地方公共団体として表明した地方公共団体
            </v-row>
            <v-row>
              <v-col>
                <v-img :src="require('../assets/reaction.png')" max-height="60" contain></v-img>
              </v-col>
            </v-row>
            <v-row class="text-lg-h6 text-md-body-1 text-body-2 font-weight-bold">
              市は「再エネ100宣言 RE
              Action（アールイー・アクション）」への参加を表明し、アンバサダーとして市民・事業者と共に、脱炭素社会に向けた持続可能な都市の実現を目指しています。
            </v-row>
          </v-col>
        </v-col>
        <v-col sm="5" cols="12">
          <v-row>
            <v-col>
              <v-img :src="require('../assets/sicho.png')"></v-img>
            </v-col>
          </v-row>
          <v-row justify="center" class="text-md-h6 text-sm-body-2 text-caption">
            さいたま市長
            <br v-if="$vuetify.breakpoint.xsOnly" />
            清水勇人
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.margin20 {
  margin-top: 20px;
}

.margin150 {
  margin-top: 150px;
}

.title-margin {
  margin-top: 80px;
  margin-bottom: 20px;
}
</style>
