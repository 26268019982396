<template>
  <v-container>
    <v-row
      id="merit"
      justify="center"
      class="text-md-h4 text-sm-h5 blue--text text--darken-2 font-weight-bold margin-top"
    >
      ＜再エネ切替のメリット＞
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-img :src="require('../assets/merit1.png')" />
      </v-col>
      <v-col cols="4">
        <v-img :src="require('../assets/merit2.png')" />
      </v-col>
      <v-col cols="4">
        <v-img :src="require('../assets/merit3.png')" />
      </v-col>
    </v-row>
    <v-row class="margin20 green lighten-1 white--text text-md-h4 text-sm-h5 font-weight-bold">
      <v-col class=" white-border margin-all10">本プロジェクトへの事業者参加の特典</v-col>
    </v-row>
    <v-row class="green lighten-1 font-weight-bold text-md-h6 text-lg-h5 text-body-2">
      <v-col>
        <!-- eslint-disable-next-line -->
        <span class="white--text">さいたま市による企業PR支援</span>として、さいたま市プレスリリース、
        <br />
        <!-- eslint-disable-next-line -->
        さいたま市ホームページ等による<span class="white--text">企業名、企業概要、地球温暖化対策の活動内容等の紹介</span>
      </v-col>
    </v-row>
    <v-row class="green lighten-1 text-caption text-md-body-1">
      <v-col>
        ※他にも、事業者にとってメリットとなるような施策、特典等を検討していますので、決定次第追加していきます
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.margin20 {
  margin-top: 20px;
}

.margin50 {
  margin-top: 50px;
}

.margin-top {
  margin-top: 80px;
  margin-bottom: 20px;
}

.white-border {
  border: 2px solid white;
}

.margin-all10 {
  margin: 10px;
}

.padding-left20 {
  padding-left: 20px;
}

.padding-right20 {
  padding-right: 20px;
}
</style>
