<template>
  <v-container fluid class="blue lighten-3">
    <v-container>
      <v-row justify="center" class="text-md-h4 text-sm-h5 font-weight-bold margin80">
        ＼申し込み後の流れ／
      </v-row>
      <v-row justify="center" class="margin20">
        <v-img :src="require('../assets/flow.png')" max-height="900" contain />
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.margin20 {
  margin-top: 20px;
}

.margin80 {
  margin-top: 80px;
}
</style>
