<template>
  <v-container fluid class="background">
    <v-row align="center" class="text-lg-body-1 text-md-body-2 font-weight-bold">
      <v-img
        :src="require('../assets/tamaenelogo.png')"
        :max-width="$vuetify.breakpoint.smAndUp ? 200 : 100"
        class="margin-left20"
      />
      <v-spacer />
      <v-row justify="space-around" class="margin-right10" v-if="$vuetify.breakpoint.mdAndUp">
        <a href="#" v-scroll-to="'#overview'"><div>プロジェクト概要</div></a>
        <a href="#" v-scroll-to="'#merit'"><div>再エネ切替メリット</div></a>
        <a href="#" v-scroll-to="'#saitama'"><div>さいたま市の取り組み</div></a>
        <a href="#" v-scroll-to="'#example'"><div>オークション活用事例</div></a>
        <a href="#" v-scroll-to="'#apply'"><div>申込方法</div></a>
        <a href="#" v-scroll-to="'#qa'"><div>よくあるご質問</div></a>
      </v-row>
    </v-row>
    <v-container>
      <v-row justify="center">
        <div class="font-weight-bold text-md-h4 text-h6 white--text teal darken-1 text-center padding">
          さいたま再エネプロジェクト
          <br v-if="$vuetify.breakpoint.xsOnly" />
          ～選ぼう、再エネ～
        </div>
      </v-row>
      <v-row justify="center" class="margin40">
        <v-img :src="require('../assets/logo.png')" max-height="80" contain />
      </v-row>
      <v-row justify="center" class="font-weight-bold text-md-h3 text-sm-h5 margin40" align="end">
        <span class="text-md-h2 text-h4 font-weight-bold teal--text text--darken-3">地球</span>
        にやさしく、
        <span class="text-md-h2 text-h4 font-weight-bold teal--text text--darken-3">お財布</span>
        にやさしい
      </v-row>
      <v-row justify="center" class="font-weight-bold text-md-h3 text-sm-h5  text-h5 margin40 margin-bottom40">
        <span class="teal--text text--darken-3">再エネ電力</span>
        に切り替え
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
a {
  text-decoration: none;
  color: black !important;
}

.margin40 {
  margin-top: 40px;
}

.margin-bottom40 {
  margin-bottom: 40px;
}

.margin-left20 {
  margin-left: 20px;
}

.margin-right10 {
  margin-right: 10px;
}

.padding {
  padding: 10px 30px;
}

.background {
  background-image: url('~@/assets/MainVisual.png');
  background-size: cover;
}
</style>
