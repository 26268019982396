import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import vuescrollto from 'vue-scrollto'
import VueGtm from 'vue-gtm'

Vue.config.productionTip = false

Vue.use(vuescrollto)

Vue.use(VueGtm, {
  id: ['GTM-59PNF94'],
})

new Vue({
  router,
  vuetify,
  vuescrollto,
  render: h => h(App),
}).$mount('#app')
