<template>
  <v-container fluid class="blue lighten-3">
    <v-container>
      <v-row id="overview" justify="center" class="text-md-h4 text-sm-h5 font-weight-bold margin80">
        ＼ プロジェクト概要 ／
      </v-row>
      <v-row class="text-md-h5 text-sm-h6 white padding-desc margin20">
        <div>
          <!-- eslint-disable-next-line -->
          本取組は、<b>脱炭素社会の実現</b>を目指すため、<b>環境省が推奨する</b>電力オークション<b>「エネオク」</b>を活用して<b>さいたま市内事業者様</b>に対して<b>最適な価格</b>で再生可能エネルギーを始めとする低炭素電力への切替をご紹介するプロジェクトです。さいたま市は株式会社エナーバンクと協定を締結し、<b>再生可能エネルギーの利活用の推進</b>を行っていきます。
        </div>
      </v-row>
      <v-row justify="center" class="white">
        <v-img :src="require('../assets/main_city3.png')" max-height="350" contain />
      </v-row>
      <v-row justify="end" class="white padding20 text-sm-body-2 text-caption">
        ※さいたま市に事業所がある事業者が本プロジェクトを活用できます
      </v-row>
      <v-row
        class="margin20 text-md-h5 text-lg-h4 text-sm-body-1 font-weight-bold"
        align="center"
        v-if="$vuetify.breakpoint.smAndUp"
      >
        <v-col cols="2" offset="1">
          <div class="white--text box-padding blue darken-3">参加費</div>
        </v-col>
        <v-col cols="2">無料</v-col>
        <v-col cols="2"><div class="white--text blue darken-3 box-padding">申込</div></v-col>
        <v-col cols="4">
          2020年12月より開始
        </v-col>
      </v-row>
      <template v-if="$vuetify.breakpoint.xsOnly">
        <v-row class="margin20 text-caption font-weight-bold" align="center">
          <div class="white--text box-padding2 blue darken-3">参加費</div>
          <div class="margin-left8">無料</div>
        </v-row>
        <v-row class="margin20 text-caption font-weight-bold" align="center">
          <div class="white--text blue darken-3 box-paddin3">申込</div>
          <div class="margin-left20">
            <v-row>
              2020年12月より開始
            </v-row>
          </div>
        </v-row>
      </template>
      <v-row justify="center" class="margin20">
        <v-btn
          color="error"
          class="font-weight-black text-h5 text-md-h4 button-radius"
          height="90"
          href="https://saitama-city.auctions.enerbank.co.jp/"
        >
          <div class="button-text">
            <div>申込はこちらから</div>
            <div class="text-sm-body-2 text-md-h6 text-caption">
              ※申込には過去12ヶ月の電気料金明細書が必要です
            </div>
          </div>
        </v-btn>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.padding-desc {
  padding: 20px 20px 0;
}

.padding20 {
  padding: 20px;
}

.box-padding {
  padding: 10px 0;
  text-align: center;
}

.box-padding2 {
  padding: 10px 25px 10px 23px;
}

.box-paddin3 {
  padding: 10px 30px;
}

.margin20 {
  margin-top: 20px;
}

.margin80 {
  margin-top: 80px;
}

.margin-left20 {
  margin-left: 20px;
}

.margin-left8 {
  margin-left: 8px;
}

.button-text {
  margin: 0 20px;
  text-align: center;
}

.button-radius {
  border-radius: 20px;
}
</style>
