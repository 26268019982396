<template>
  <v-container fluid class="blue darken-3">
    <v-row id="apply" justify="center" class="text-md-h4 text-sm-h5 white--text font-weight-bold margin80">
      ＼申込方法／
    </v-row>
    <v-row justify="center" class="margin30">
      <v-btn
        color="error"
        class="font-weight-black text-h5 text-md-h4 button-radius"
        height="90"
        href="https://saitama-city.auctions.enerbank.co.jp/"
      >
        <div class="button-text">
          <div>申込はこちらから</div>
          <div class="text-sm-body-2 text-md-h6 text-caption">
            ※申込には過去12ヶ月の電気料金明細書が必要です
          </div>
        </div>
      </v-btn>
    </v-row>
    <v-row class="margin-bottom50">
      <v-col class="white">
        <v-container class="text-md-h6 text-lg-h5 text-body-2">
          <v-row>
            <v-col sm="4" cols="12">
              <v-row justify="center" class="blue--text">
                [電力使用明細サンプル]
              </v-row>
              <v-row>
                <v-col>
                  <v-img :src="require('../assets/electricbill.png')"></v-img>
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="8" cols="12" class="margin20" align-self="center">
              <v-row>
                <v-col cols="1">
                  <v-icon>fas fa-check</v-icon>
                </v-col>
                <v-col cols="11">
                  お申込にあたり
                  <span class="blue--text text-decoration-underline">過去12ヵ月分の電力使用明細</span>
                  が必要となりますので、あらかじめご準備の上お申し込みください。
                </v-col>
                <v-col cols="1">
                  <v-icon>fas fa-check</v-icon>
                </v-col>
                <v-col cols="11">
                  お申込み時に、小売電気事業者から提案される
                  <span class="blue--text text-decoration-underline">
                    電力契約の再エネ比率を10％、30％、50％、100％から指定
                  </span>
                  いただけます。
                </v-col>
                <v-col cols="1">
                  <v-icon>fas fa-check</v-icon>
                </v-col>
                <v-col cols="11">
                  指定がない場合は、再エネ比率30％でオークションを実施します。
                </v-col>
                <v-col cols="11" offset="1" class="text-md-body-2 text-lg-h6 text-caption">
                  ※上記以外の比率で電力契約を締結する場合、契約予定の小売電気事業者と需要家様で個別交渉していただく必要があります。
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.margin20 {
  margin-top: 20px;
}

.margin30 {
  margin: 30px 0;
}

.margin80 {
  margin-top: 80px;
}

.margin-bottom50 {
  margin-bottom: 50px;
}

.button-text {
  margin: 0 20px;
  text-align: center;
}

.button-radius {
  border-radius: 20px;
}
</style>
