<template>
  <v-footer class="background">
    <v-container class=" font-weight-bold text-lg-body-1 text-md-body-2">
      <v-row justify="space-around">
        <a href="#" v-scroll-to="'#overview'"><div>プロジェクト概要</div></a>
        <a href="#" v-scroll-to="'#merit'"><div>再エネ切り替えメリット</div></a>
        <a href="#" v-scroll-to="'#saitama'"><div>さいたま市の取り組み</div></a>
        <a href="#" v-scroll-to="'#example'"><div>オークション活用事例</div></a>
        <a href="#" v-scroll-to="'#apply'"><div>申込方法</div></a>
        <a href="#" v-scroll-to="'#qa'"><div>よくあるご質問</div></a>
      </v-row>
      <v-row justify="space-around" class="margin20">
        <a href="https://www.city.saitama.jp/"><div>さいたま市ホームページ</div></a>
        <a href="https://www.enerbank.co.jp/"><div>株式会社エナーバンク</div></a>
        <a href="https://www.enerbank.co.jp/privacy"><div>プライバシーポリシー</div></a>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {}
</script>

<style scoped>
a {
  text-decoration: none;
  color: black !important;
}

.margin20 {
  margin-top: 20px;
}

.background {
  background-image: url('~@/assets/footter.png');
  background-size: cover;
}
</style>
