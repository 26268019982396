<template>
  <v-app>
    <v-main>
      <Header />
      <OverView />
      <Merit />
      <Saitama />
      <Auction />
      <Example />
      <Apply />
      <Flow />
      <QA />
    </v-main>
    <Footer v-if="$vuetify.breakpoint.mdAndUp" />
  </v-app>
</template>

<script>
import Header from './components/Header'
import OverView from './components/OverView'
import Merit from './components/Merit'
import Saitama from './components/Saitama'
import Auction from './components/Auction'
import Example from './components/Example'
import Apply from './components/Apply'
import Flow from './components/Flow'
import QA from './components/QA'
import Footer from './components/Footer'

export default {
  name: 'App',

  components: {
    Header,
    OverView,
    Merit,
    Saitama,
    Auction,
    Example,
    Apply,
    Flow,
    QA,
    Footer,
  },

  data: () => ({
    //
  }),
}
</script>
