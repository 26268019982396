<template>
  <v-container>
    <v-row id="qa" class="text-lg-h4 text-md-h5 text-sm-h6 text-body-1 margin80" justify="center">
      <b>＜本プロジェクトへの参加を希望する小売電気事業者への注意事項＞</b>
      <span class="text-md-h5 text-sm-h6 text-body-1">
        オークション参加には、株式会社エナーバンクとの間で「エネオク」の利用に係る契約が必要となります。
        <br />
        株式会社エナーバンクとの契約に関しては、以下よりお問い合わせください。
      </span>
    </v-row>
    <v-row justify="center" class="margin40">
      <v-btn
        color="#BDBDBD"
        class="font-weight-black white--text text-md-h4 text-sm-h5 text-body-1 button-radius"
        height="100"
        href="https://form.run/@retailer"
      >
        <div class="button-text">
          <div>小売電気事業者の参加に関する</div>
          <div>お問い合わせ</div>
        </div>
      </v-btn>
    </v-row>

    <v-row justify="center" class="text-md-h4 text-sm-h5 font-weight-bold under-line margin40">
      よくあるご質問
    </v-row>
    <v-row justify="center" class="text-md-h5 text-sm-h6 margin40">
      よくあるご質問に関する
      <b>Q&amp;A</b>
      は
      <a href="./pdf/faq.pdf" target="_blank">こちら</a>
    </v-row>
    <v-row class="margin40">
      <v-col cols="12">
        <v-row justify="center">
          <v-btn
            color="info"
            class="font-weight-black text-h5 text-md-h4 button-radius"
            height="130"
            href="https://form.run/@saitama-city"
          >
            <div class="button-text">
              <div>オークションについての</div>
              <div>問合せはこちらから</div>
              <div class="text-body-2 text-md-h6">※問合せ窓口：㈱エナーバンク</div>
            </div>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <div class="font-weight-black text-h5 text-md-h4 button-radius card">
            <div>事業全般についての</div>
            <div>問合せはこちらから</div>
            <div class="text-body-2 text-md-h6">さいたま市 環境局 環境共生部 脱炭素社会推進課</div>
            <div class="text-caption text-md-body-1">〒330-9588 さいたま市浦和区常盤6-4-4</div>
            <div class="text-caption text-md-body-1">TEL：048-829-1324 FAX：048-829-1991</div>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" class="font-weight-black text-md-h4 text-sm-h5 margin40" align="end">
      <span class="text-h5 text-md-h3 text-sm-h4 font-weight-bold teal--text text--darken-3">地球</span>
      にやさしく、
      <span class="text-h5 text-md-h3 text-sm-h4 font-weight-bold teal--text text--darken-3">お財布</span>
      にやさしい
    </v-row>
    <v-row justify="center" class="font-weight-black text-md-h4 text-sm-h5">
      <span class="teal--text text--darken-3">再エネ電力</span>
      に切り替えはこちらから
    </v-row>
    <v-row justify="center" class="margin10">
      <v-icon color="grey" x-large>fa-arrow-down</v-icon>
    </v-row>
    <v-row justify="center" class="margin10">
      <v-btn
        color="error"
        class="font-weight-black text-h5 text-md-h4 button-radius"
        height="90"
        href="https://saitama-city.auctions.enerbank.co.jp/"
      >
        <div class="button-text">
          <div>お申込ページへ</div>
          <div class="text-sm-body-2 text-md-h6 text-caption">
            ※申込には過去12ヶ月の電気料金明細書が必要です
          </div>
        </div>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
.under-line {
  border-bottom: 5px solid #0d47a1;
}

.margin10 {
  margin-top: 10px;
}

.margin40 {
  margin-top: 40px;
}

.margin80 {
  margin-top: 80px;
}

.button-text {
  margin: 0 20px;
  text-align: center;
}

.button-radius {
  border-radius: 20px;
}

.card {
  border: 3px solid #43a047;
  padding: 20px;
  text-align: center;
}
</style>
