<template>
  <v-container fluid class="grey lighten-3">
    <v-container>
      <v-row
        id="example"
        justify="center"
        class="text-md-h4 text-sm-h5 text-body-2 font-weight-bold align-end margin80"
      >
        <v-img :src="require('../assets/graph.png')" max-height="70" max-width="70" class="margin-right20"></v-img>
        電力リバースオークション「エネオク」の活用実績
      </v-row>
      <v-row class="text-md-h6 text-lg-h5 text-body-2">
        <v-col>
          株式会社エナーバンクが運営する電力リバースオークションサービス「エネオク」はRE100実現のひとつの方策
          として、2020年6月19日に環境省が公表した「公的機関のための再エネ調達実践ガイド「気候変動時代に公的機
          関ができること～「再エネ100％」への挑戦～」に掲載されています。
          <b>
            エネオクのデジタルソリューションを活用して、効率的・効果的にグリーン化（再エネの調達、RE100の達成）を実現しています。
          </b>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="6" cols="12">
          <v-row justify="center">
            <v-img :src="require('../assets/guide.png')" />
          </v-row>
          <v-row justify="center" class="text-caption">
            環境省「公的機関のための再エネ調達実践ガイド」にエネオク掲載
          </v-row>
          <v-row justify="center" class="text-caption text-sm-body-2">
            <a href="https://prtimes.jp/main/html/rd/p/000000024.000038798.html" target="_blank">報道発表はこちら</a>
          </v-row>
        </v-col>
        <v-col sm="6" cols="12">
          <v-row justify="center">
            <v-img :src="require('../assets/kankyosho.png')" />
          </v-row>
          <v-row justify="center" class="text-caption">
            伊勢志摩国立公園横山ビジターセンター等6施設のRE100電力調達をエネオクで事業者選定
          </v-row>
          <v-row justify="center" class="text-caption text-sm-body-2">
            <a href="https://prtimes.jp/main/html/rd/p/000000031.000038798.html" target="_blank">報道発表はこちら</a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {}
</script>

<style scoped>
a {
  color: #ec407a !important;
}

.margin-right20 {
  margin-right: 20px;
}

.margin80 {
  margin-top: 80px;
}
</style>
